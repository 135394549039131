<template>
  <b-badge :variant="getColorTag(value)">
    {{ descricao }}
  </b-badge>
</template>

<script>
export default {
  name: 'CndSituacao',
  props: {
    value: {
      type: String,
      required: true,
    },
    descricao: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColorTag(value) {
      if (value === 'regular') {
        return 'light-success'
      }
      if (value === 'empregadornaocadastrado') {
        return 'light-secondary'
      }
      return value === 'irregular' ? 'light-danger' : 'light-primary'
    },
  },
}
</script>
